<template>
  <el-dialog
    title="选择账户"
    :visible="show"
    width="500px"
    @close="close"
    center
  >
    <div class="accountFormTT">
      <div
        v-for="data in dataList"
        :key="data.bc_id"
        class="dp-flex align-item-c justify-btw border-bt-1 py-10"
      >
        <div>
          <div>账号信息：{{ data.adv_name }}</div>
          <div class="tips">所属个号：{{ data.uid }}</div>
          <div class="tips">所属BC：{{ data.bc_name }} ({{ data.bc_id }})</div>
        </div>
        <div>
          <el-button
            type="primary"
            size="mini"
            @click="goto(data)"
            >进入账户</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean,
    dataList: Array,
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    goto({uid, bc_id, adv_id,campaigns}) { // id是账户ID，bc是bcID，num是个号ID
      window.open(`${process.env.VUE_APP_TT}ad/adManagement?id=${adv_id}&bc=${bc_id}&num=${uid}&campaign_id=${campaigns[0].cam_id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.accountFormTT {
  max-height: 80vh;
  .tips {
    color: #999999;
  }
}
</style>
