<template>
  <div>
    <div>
      <div v-if="budgetType.length">
        <div>
          <div class="dp-flex align-item-c">
            <div>
              <div
                v-for="type in budgetType"
                :key="type"
              >
                <span>{{ budgetNames[type] }}:</span>
                <span
                  >{{ msg.statistics.currencySymbol
                  }}{{
                    type == 'dailyBudget'
                      ? msg.ad.adGroupDailyBudget || msg.ad.campaignDailyBudget
                      : msg.ad.adGroupLifetimeBudget || msg.ad.campaignLifetimeBudget
                  }}</span
                >
              </div>
            </div>
            <i
              class="el-icon-edit"
              style="color: #409eff"
              v-show="!(level == 'adset' && msg.ad.activeCbo && msg.ad.platform == 'line')"
              @click="$emit('editClick', msg)"
            ></i>
          </div>
          <el-button
            type="primary"
            icon="el-icon-alarm-clock"
            size="mini"
            plain
            round
            @click="budgetClock"
            v-show="showbuggetClock"
            >定时改预算</el-button
          >
        </div>
      </div>
      <div v-else>{{ level == 'campaign' ? '组预算' : '系列预算' }}</div>
    </div>
  </div>
</template>

<script>
import setTimeEditBudget from '@/views/adManagement/components/table/setTimeEditBudget.vue';

const defaultRules = {
  bidMin: 0.01,
  bidMax: 1000.0,
  bidScale: 2,
  campaignBudgetMin: 0.01,
  campaignBudgetMax: 1000000.0,
  adsetBudgetMin: 0.01,
  adsetBudgetMax: 1000000.0,
  budgetScale: 2,
};

export default {
  props: {
    msg: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
  },
  data() {
    return {
      newBudget: '',
      checkRules: defaultRules,
      newValue: '',
      budgetNames: {
        dailyBudget: '日预算',
        lifetimeBudget: '总预算',
      },
    };
  },
  components: { setTimeEditBudget },
  computed: {
    budgetType() {
      let budgetTypes = [];

      if (this.msg.ad.campaignDailyBudget || this.msg.ad.adGroupDailyBudget) {
        budgetTypes.push('dailyBudget');
      }
      if (this.msg.ad.campaignLifetimeBudget || this.msg.ad.adGroupLifetimeBudget) {
        budgetTypes.push('lifetimeBudget');
      }
      return budgetTypes;
    },
    showbuggetClock() {
      if (this.level == 'campaign') {
        return this.msg.ad.adCampaignStatus == 'ACTIVE';
      }
      if (this.level == 'adset') {
        if (this.msg.ad.activeCbo && this.msg.ad.platform == 'line') {
          return false;
        }
        return this.msg.ad.adGroupStatus == 'ACTIVE';
      }
    },
  },
  methods: {
    budgetClock() {
      this.$emit('editClick', this.msg, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-edit {
  margin-left: 10px;
  cursor: pointer;
}
</style>
