var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dp-flex align-item-c AIRecommendation"},[_c('div',{class:[
      'iconfont',
      'icons',
      _vm.AiSuggestionTypes[_vm.aiSuggestion.type].icon,
      _vm.AiSuggestionTypes[_vm.aiSuggestion.type].class,
      _vm.isOverTime(_vm.aiSuggestion.suggestionTime) ? 'overTime' : '',
    ]}),_c('span',{class:{
      [_vm.AiSuggestionTypes[_vm.aiSuggestion.type].class]: true,
      overTime: _vm.isOverTime(_vm.aiSuggestion.suggestionTime),
    }},[_vm._v(" "+_vm._s(_vm.aiSuggestion.message)+" ")]),_c('div',{staticClass:"timeGrey",class:{ overTime: _vm.isOverTime(_vm.aiSuggestion.suggestionTime) }},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm.aiSuggestion.suggestionTime.split(' ')[1].slice(0, 5))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }