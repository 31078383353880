<template>
  <div>
    <div
      :style="{
        color: data.adAccountExceptional ? 'red' : 'inherit',
        cursor: ['facebook', 'tiktok'].includes(data.platform) ? 'pointer' : 'auto',
      }"
      @click="gotoAccount(data.campaignId, data.platform)"
    >
      <div>{{ data.adAccountName }}</div>
      <div
        :style="{
          color: data.adAccountExceptional ? 'red' : '#C0C4CC',
        }"
      >
        ID：{{ data.adAccountId }}
      </div>
    </div>

    <accountFromTT
      :show.sync="showSelectAccount"
      :dataList="dataList"
    ></accountFromTT>
  </div>
</template>
<script>
import { switchActByKeyword, queryAdsByRels } from '@/api/multiPlatform';
import accountFromTT from './accountFromTT.vue';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { accountFromTT },
  data() {
    return {
      showSelectAccount: false,
      dataList: [],
    };
  },
  methods: {
    // 跳转fb
    getFbAccount(campaignId, platform) {
      let data = { keyword: campaignId, platform };
      switchActByKeyword(data).then((res) => {
        if (res.data.length > 0) {
          window.open(
            `${process.env.VUE_APP_FB}ad/adManagement?account_id=${res.data[0].actId}&third_user_id=${res.data[0].thirdUserId}&campaign_id=${campaignId}`,
          );
        }
      });
    },
    // 跳转tt
    getTTAccount(campaignId) {
      let params = {
        keyword: campaignId,
      };
      const loading = this.$loading();
      queryAdsByRels(params)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data.length == 1) {
              window.open(
                `${process.env.VUE_APP_TT}ad/adManagement?id=${res.data[0].adv_id}&bc=${res.data[0].bc_id}&num=${res.data[0].uid}&campaign_id=${campaignId}`,
              );
            } else {
              this.showSelectAccount = true;
              this.dataList = res.data;
            }
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    gotoAccount(campaignId, platform) {
      switch (platform) {
        case 'facebook':
          this.getFbAccount(campaignId, platform);
          break;
        case 'tiktok':
          this.getTTAccount(campaignId);
          break;
      }
    },
  },
};
</script>
