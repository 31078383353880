<template>
  <el-drawer
    title="异常信息"
    :visible="show"
    :destroy-on-close="true"
    size="800px"
    @open="onOpend"
    @close="close"
  >
    <div
      class="w-100 h100 p-10 abnormal"
      v-loading="loading"
    >
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        class="h100 dp-flex flex-d-c tabs"
      >
        <el-tab-pane
          label="广告账户无凭证"
          name="NO_CREDENTIAL"
          lazy
        >
          <div class="dangerous">以下广告账户无凭证，请到电脑端凭证管理中添加凭证或者同步凭证广告账户</div>
          <div class="info mb-10">不能进行查看实时花费、开关、调整预算、查看广告组等操作</div>
          <el-table :data="NO_CREDENTIAL.list">
            <el-table-column
              label="广告账户名称"
              prop="account_name"
            ></el-table-column>
            <el-table-column
              label="ID"
              prop="account_id"
            ></el-table-column>
          </el-table>
          <div style="margin-top: 10px; text-align: right">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="NO_CREDENTIAL.pageNumber"
              :page-size="NO_CREDENTIAL.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="NO_CREDENTIAL.pageTotal"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="凭证失效"
          name="OTHER"
          lazy
        >
          <div class="dangerous">以下凭证失效，请到电脑端凭证管理中添加凭证或者同步凭证广告账户</div>
          <div class="info mb-10">不能进行查看实时花费、开关、调整预算、查看广告组等操作</div>
          <el-table :data="OTHER.list">
            <el-table-column
              label="广告账户名称"
              prop="account_name"
            ></el-table-column>
            <el-table-column
              label="ID"
              prop="account_id"
            ></el-table-column>
          </el-table>
          <div style="margin-top: 10px; text-align: right">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="OTHER.pageNumber"
              :page-size="OTHER.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="OTHER.pageTotal"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>
<script>
import { selectExceptional } from '@/api/multiPlatform';
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      loading: false,
      activeName: 'NO_CREDENTIAL',
      NO_CREDENTIAL: {
        pageNumber: 1,
        pageSize: 20,
        pageTotal: 0,
        list: [],
      },
      OTHER: {
        pageNumber: 1,
        pageSize: 20,
        pageTotal: 0,
        list: [],
      },
    };
  },
  methods: {
    onOpend() {
      this.activeName = 'NO_CREDENTIAL';
      this.NO_CREDENTIAL = {
        pageNumber: 1,
        pageSize: 20,
        list: [],
        pageTotal: 0,
      };
      this.OTHER = {
        pageNumber: 1,
        pageSize: 20,
        list: [],
        pageTotal: 0,
      };
      this.getList();
    },

    handleSizeChange(val) {
      this.$set(this[this.activeName], 'pageSize', val);
      this.$set(this[this.activeName], 'pageNumber', 1);
      this.getList();
    },
    handleCurrentChange(val) {
      this.$set(this[this.activeName], 'pageNumber', val);
      this.getList();
    },
    getList() {
      this.loading = true;
      let params = {
        platform: this.platform,
        pageNumber: this[this.activeName].pageNumber,
        pageSize: this[this.activeName].pageSize,
        errorType: this.activeName,
      };
      selectExceptional(params)
        .then((res) => {
          this.$set(this[this.activeName], 'list', res.list);
          this.$set(this[this.activeName], 'pageTotal', res.total);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClick({ name }) {
      this.activeName = name;
      if (this[name].list.length == 0 && this[name].pageNumber == 1) {
        this.getList();
      }
    },
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>
<style>
.abnormal {
  .el-tabs__content {
    flex: 1;
    height: 0;
    .el-tab-pane {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
    }
  }
  .dangerous {
    color: #f00;
  }
  .info {
    color: #999;
    font-size: 12px;
  }
}
</style>
