<template>
  <el-dialog
    :visible="show"
    width="500px"
    title="调整预算"
    @close="close"
    @open="openInit"
  >
    <div v-loading="loading">
      <el-form
        :model="formData"
        :rules="rules"
        label-width="120px"
        ref="form"
      >
        <el-form-item
          label="触发时间"
          prop="timeStr"
          v-if="isClock"
        >
          <el-date-picker
            v-model="formData.timeStr"
            type="datetime"
            placeholder="选择日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="level == 'campaign' ? '系列总预算' : '广告组总预算'"
          prop="lifetimeBudget"
          class="flex-1"
          required
          v-if="budgetMsg.hasLifetimeBudget"
        >
          <el-input
            v-model="formData.lifetimeBudget"
            v-to-fixed="platformRules.scal || 2"
            :disabled="level == 'adset' && data.ad.activeCbo && data.ad.platform == 'line'"
          >
            <template slot="append">{{ data.statistics.currency }}</template>
          </el-input>
        </el-form-item>
        <el-form-item
          :label="level == 'campaign' ? '系列日预算' : '广告组日预算'"
          prop="dailyBudget"
          class="flex-1"
          required
          v-if="budgetMsg.hasDailyBudget"
        >
          <el-input
            v-model="formData.dailyBudget"
            v-to-fixed="platformRules.scal || 2"
            :disabled="level == 'adset' && data.ad.activeCbo && data.ad.platform == 'line'"
          >
            <template slot="append">{{ data.statistics.currency }}</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dp-flex justify-end">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
const defaultRules = {
  bidMin: 0.01,
  bidMax: 1000.0,
  bidScale: 2,
  campaignBudgetMin: 0.01,
  campaignBudgetMax: 1000000.0,
  adsetBudgetMin: 0.01,
  adsetBudgetMax: 1000000.0,
  budgetScale: 2,
};
import { queryConfigDataCheck, mutateBudget, settingAdScheduleUpdateBudgetTask } from '@/api/multiPlatform';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    show: Boolean,
    level: String,
    isClock: Boolean, // 是否是定时
    data: {
      type: Object,
      default: () => ({ statistics: {}, ad: {} }),
    },
  },
  data() {
    return {
      formData: {
        lifetimeBudget: '',
        dailyBudget: '',
      },
      loading: false,
      checkRules: defaultRules,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
        start: '00:00',
        step: '00:30',
        end: '23:30',
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    budgetMsg() {
      let data = this.data?.ad || {};
      if (this.level == 'campaign') {
        return {
          hasDailyBudget: !!data.campaignDailyBudget,
          hasLifetimeBudget: !!data.campaignLifetimeBudget,
        };
      } else if (this.level == 'adset') {
        return {
          hasDailyBudget: !!data.adGroupDailyBudget,
          hasLifetimeBudget: !!data.adGroupLifetimeBudget,
        };
      }
      return {};
    },
    platformRules() {
      let rules = this.checkRules;
      return {
        budgetMin: this.level == 'campaign' ? rules.campaignBudgetMin : rules.adsetBudgetMin || 0.01,
        budgetMax: this.level == 'campaign' ? rules.campaignBudgetMax : rules.adsetBudgetMax || 100,
        scal: rules.budgetScale,
      };
    },
    rules() {
      let rules = {
        dailyBudget: [
          {
            validator: (rule, value, callback) => {
              if (value / 1 >= this.platformRules.budgetMin && value / 1 <= this.platformRules.budgetMax) {
                callback();
              } else {
                callback(false);
              }
            },
            message: `请输入${this.platformRules.budgetMin} - ${this.platformRules.budgetMax}之间的数字`,
            trigger: ['blur'],
          },
        ],
        lifetimeBudget: [
          {
            validator: (rule, value, callback) => {
              if (value / 1 >= this.platformRules.budgetMin && value / 1 <= this.platformRules.budgetMax) {
                callback();
              } else {
                callback(false);
              }
            },
            message: `请输入${this.platformRules.budgetMin} - ${this.platformRules.budgetMax}之间的数字`,
            trigger: ['blur'],
          },
        ],
        timeStr: [
          {
            required: true,
            message: '请选择时间',
          },
        ],
      };
      return rules;
    },
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    openInit() {
      this.loading = false;
      this.formData = {
        lifetimeBudget: '',
        dailyBudget: '',
      };
      this.getCheckRules();
      let data = this.data.ad;
      if (this.data.ad.platform == 'google' && this.data.ad.objectTive == 'VIDEO') {
        this.staticBid = false;
      }

      if (this.isClock) {
        const openInfo = (data.open_schedule || []).find((item) => item.type == 2) || {};
        this.$set(
          this.formData,
          'timeStr',
          openInfo.timeStr || moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        );
        const lifetimeBudget = {
          campaign: 'campaignLifetimeBudget',
          adset: 'adGroupLifetimeBudget',
        };
        const dailyBudget = {
          campaign: 'campaignDailyBudget',
          adset: 'adGroupDailyBudget',
        };

        if (this.budgetMsg.hasLifetimeBudget) {
          this.$set(
            this.formData,
            'lifetimeBudget',
            openInfo.lifetimeBudget || openInfo.budget || data[lifetimeBudget[this.level]],
          );
        }
        if (this.budgetMsg.hasDailyBudget) {
          this.$set(
            this.formData,
            'dailyBudget',
            openInfo.dailyBudget || openInfo.budget || data[dailyBudget[this.level]],
          );
        }

        return;
      }

      this.$set(
        this.formData,
        'dailyBudget',
        this.level == 'campaign' ? data.campaignDailyBudget : data.adGroupDailyBudget,
      );
      this.$set(
        this.formData,
        'lifetimeBudget',
        this.level == 'campaign' ? data.campaignLifetimeBudget : data.adGroupLifetimeBudget,
      );
    },
    getCheckRules() {
      queryConfigDataCheck({
        platform: this.data.ad.platform,
        currency: this.data.currency,
        objectiveEnum: this.data.ad.objectiveEnum,
      }).then((res) => {
        this.checkRules = res.data || defaultRules;
      });
    },
    saveClock() {
      const data = {
        adAccountId: this.data.ad.adAccountId,
        platform: this.data.ad.platform,
        handleTime: this.formData.timeStr,
        clientType: 'mobile',
        taskType: 2,
        id: this.level == 'campaign' ? this.data.ad.campaignId : this.data.ad.adGroupId,
        level: this.level,
        gimpUserId: this.userInfo.id,
        dailyBudget: this.formData.dailyBudget || null,
        lifetimeBudget: this.formData.lifetimeBudget || null,
        objectiveEnum: this.data.ad.objectiveEnum,
        campaignId: this.data.ad.campaignId,
        adGroupId:  this.data.ad.adGroupId,
        adGroupAdId: this.data.ad.adId
      };

      settingAdScheduleUpdateBudgetTask(data)
        .then((res) => {
          if (res.code == 0) {
            let oldOPenSchedule = (this.data.ad.open_schedule || []).filter((item) => item.type != 2);
            const newOpenSchedule = [res.data, ...oldOPenSchedule];
            this.$set(this.data.ad, 'open_schedule', [...newOpenSchedule]);

            this.$message.success('修改成功');
            this.close();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      let params = {
        platform: this.data.ad.platform,
        level: this.level,
        accountId: this.data.ad.adAccountId,
        campaignId: this.data.ad.campaignId,
        adsetId: this.data.ad.adGroupId,
        dailyBudget: this.formData.dailyBudget || null,
        lifetimeBudget: this.formData.lifetimeBudget || null,
        clientType: 'pc',
        functionType: 'manual',
      };

      this.loading = true;
      mutateBudget(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('修改成功');
            // 修改预算
            if (this.level == 'campaign') {
              this.$set(this.data.ad, 'campaignDailyBudget', this.formData.dailyBudget);
              this.$set(this.data.ad, 'campaignLifetimeBudget', this.formData.lifetimeBudget);
            } else if (this.level == 'adset') {
              this.$set(this.data.ad, 'adGroupLifetimeBudget', this.formData.lifetimeBudget);
              this.$set(this.data.ad, 'adGroupDailyBudget', this.formData.dailyBudget);
            }
            this.close();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sure() {
      if (this.loading) return;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.isClock) {
            this.saveClock();
          } else {
            this.save();
          }
        }
      });
    },
  },
};
</script>
