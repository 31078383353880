<template>
  <div class="dp-flex align-item-c AIRecommendation">
    <div
      :class="[
        'iconfont',
        'icons',
        AiSuggestionTypes[aiSuggestion.type].icon,
        AiSuggestionTypes[aiSuggestion.type].class,
        isOverTime(aiSuggestion.suggestionTime) ? 'overTime' : '',
      ]"
    ></div>
    <span
      :class="{
        [AiSuggestionTypes[aiSuggestion.type].class]: true,
        overTime: isOverTime(aiSuggestion.suggestionTime),
      }"
    >
      {{ aiSuggestion.message }}
    </span>
    <div
      class="timeGrey"
      :class="{ overTime: isOverTime(aiSuggestion.suggestionTime) }"
    >
      <i class="el-icon-time"></i> {{ aiSuggestion.suggestionTime.split(' ')[1].slice(0, 5) }}
    </div>
  </div>
</template>
<script>
const AiSuggestionTypes = {
  PAUSE: {
    icon: 'icon-guanting',
    class: 'cF88',
  },
  SUB_BUDGET: {
    icon: 'icon-zengjiayusuan-copy',
    class: 'cFB6',
  },
  ADD_BUDGET: {
    icon: 'icon-zengjiayusuan',
    class: 'c80D580',
  },
  NO_OP: {
    icon: 'icon-xianzhuang',
    class: 'c99bbff',
  },
};
export default {
  props: {
    aiSuggestion: Object,
  },
  data() {
    return {
      AiSuggestionTypes,
    };
  },
  methods: {
    //检查AI助手建议时间是不是超过了半小时
    isOverTime(givenDateTime) {
      let now = new Date();
      let diffInMinutes = (new Date(givenDateTime) - now) / (1000 * 60);
      let isOver30Minutes = Math.abs(diffInMinutes) > 30;
      return isOver30Minutes;
    },
  },
};
</script>
<style scoped>
.AIRecommendation {
  margin-top: 10px;
  font-size: 12px;
  .icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .c80D580 {
    color: #80d580;
  }
  .c99bbff {
    color: #99bbff;
  }
  .cFB6 {
    color: #ffbb66;
  }
  .cF88 {
    color: #ff8888;
  }

  .timeGrey {
    color: #aaaaaa;
  }
  .overTime {
    color: #bbbbbb;
  }
  span {
    color: #ccddff;
    margin-top: 2px;
  }
}
</style>
